import $ from 'jquery'
import './main.scss'

var chat_loaded = false

function loadChat(userID) {
  if (chat_loaded) {
    return
  }

  chat_loaded = true
  var container = $('.simplero-chat__iframe-container')
  var iframe = $(
    '<iframe class="simplero-chat__iframe" frameborder="0" src="' +
      container.data('url') +
      (userID ? '?user_id=' + userID : '') +
      '"></iframe>'
  )

  container.append(iframe)
}

$(document).on('click', '.site-header__left-nav-chat > a', function (event) {
  event.preventDefault()

  loadChat()

  $('.simplero-chat').toggleClass('simplero-chat--closed')
})

$(document).on('click', '.site-header__nav-chat > a', function (event) {
  event.preventDefault()

  loadChat()

  $('.simplero-chat').toggleClass('simplero-chat--closed')
})

$(document).on('click', '.simplero-chat__close', function (event) {
  event.preventDefault()

  loadChat()

  $('.simplero-chat').toggleClass('simplero-chat--closed')
})

$(document).on('click', '[data-trigger-chat-with]', function (e) {
  e.preventDefault()
  e.stopPropagation()

  var userID = $(this).data('trigger-chat-with')

  loadChat(userID)

  $('.simplero-chat').toggleClass('simplero-chat--closed')
})
